import React from "react";
import { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import useBreakpoint from "../utils/useBreakpoint";
import Image from "./Image";
import { mobile, mobileUp } from "../styles/global";

const Video = ({ video, poster, cover, className }) => {
	const { ref, inView, entry } = useInView({ threshold: 0.2 });
	const videoRef = useRef();

	const { isMobile } = useBreakpoint();

	const handleExit = () => {
		videoRef?.current?.pause();
	};

	const handleEntry = () => {
		videoRef?.current?.play();
	};

	useEffect(() => {
		if (!inView) {
			handleExit();
		} else {
			handleEntry();
		}
	}, [inView]);

	return (
		<>
			<Wrap
				className={className}
				ref={ref}
				cover={cover}
				css={css`
					background-color: ${poster?.asset?.metadata?.palette?.dominant
						?.background};
					${mobileUp} {
					}
				`}
			>
				{poster && <Poster cover image={poster} />}
				<VideoTag
					className={`cover-${cover}${inView ? "" : " not-"}inView`}
					ref={videoRef}
					playsInline
					muted
					autoPlay
					loop
					src={video}
					cover={cover}
				/>
			</Wrap>
		</>
	);
};

const Wrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
	height: ${(props) => props.cover && "100%"};
	flex: ${(props) => props.cover && 1};
`;

const VideoTag = styled.video`
	width: 100%;
	${mobileUp} {
		height: ${(props) => props.cover && "100%"};
		object-fit: ${(props) => (props.cover ? "cover" : "contain")};
	}
	${mobile} {
		height: auto;
		object-fit: ${(props) => (props.cover ? "cover" : "contain")};
	}
`;

const Poster = styled(Image)`
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0;
	pointer-events: none;
	opacity: ${(props) => !props.showPoster && 0};
	transition: opacity var(--transition);
`;

export default Video;
