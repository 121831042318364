import React from "react";
import { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import useBreakpoint from "../utils/useBreakpoint";
import { mobile, mobileUp, tablet } from "../styles/global";
// import { Swiper, SwiperSlide, Pagination } from "swiper/react";

import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
// import Image from "../components/Image";
// import BlockText from "../components/BlockText";
// import Slider from "../components/Slider";
import ProjectSlide from "./ProjectSlide";
import Section from "./Section";
import BlockText from "./BlockText";

// Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

const ProjectContent = ({ project, content }) => {
	const { isMobile } = useBreakpoint();
	const [activeSlide, setActiveSlide] = useState();

	// const [visibleSlides, setVisibleSlides] = useState([]);
	// const [scrolling, setScrolling] = useState(false);
	// const [prevY, setPrevY] = useState(undefined);
	// const [newY, setNewY] = useState(undefined);

	const [showNav, setShowNav] = useState();
	const slideCount = content?.length;
	let threshold;
	if (slideCount) {
		threshold = (1 / slideCount) * (1 / 2);
	} else {
		threshold = 1;
	}

	const [ref, inView, entry] = useInView({
		threshold: threshold,
	});
	const [infoRef, infoInView, infoEntry] = useInView({
		threshold: 0.5,
	});

	useEffect(() => {
		setShowNav(inView);
	}, [inView]);

	useEffect(() => {
		if (infoInView) {
			setActiveSlide(0);
		}
	}, [infoInView]);

	let el;

	const [darkSlide, setDarkSlide] = useState();
	useEffect(() => {
		if (content[activeSlide - 1]?.slideType === "fullBleed") {
			setDarkSlide(true);
		} else {
			setDarkSlide(false);
		}
	}, [activeSlide]);

	return (
		<>
			<Global
				styles={css`
					:root {
						--fg: ${inView &&
						content[activeSlide - 1]?.slideType === "fullBleed"
							? "var(--white)"
							: "var(--black"};
						--header-bg: ${inView &&
						content[activeSlide - 1]?.slideType === "fullBleed"
							? "transparent"
							: "var(--white)"};

						${mobile} {
							--header-bg: var(--white);
							--fg: var(--black);
						}
					}
				`}
			/>
			<ContentWrap ref={ref}>
				{!isMobile && (
					<SlideNav showNav={showNav}>
						{content.map((slide, i) => (
							<NavDot
								key={`navdot-${project.slug}-${slide._key}`}
								onClick={() => {
									el = document?.getElementById(`slide-${i}`);
									el?.scrollIntoView({ behavior: "smooth" }, true);
									setActiveSlide(i);
								}}
								className={
									content[activeSlide - 1]?.slideType === "fullBleed"
										? "dark"
										: "light"
								}
								css={css`
									&.dark {
										background-color: ${activeSlide === i
											? "var(--white)"
											: "rgba(185, 185, 185, 0.75);"};
									}
									&.light {
										background-color: ${activeSlide === i
											? "var(--grey-dark)"
											: "rgba(185, 185, 185, 0.35);"};
									}
								`}
							/>
						))}
					</SlideNav>
				)}
				<ProjectInfo ref={infoRef} id={`slide-0`}>
					<ProjectMeta>
						{project.location && (
							<DetailsItem smallFirstCol>
								<p>Location</p>
								<p className="span-end">{project.location}</p>
							</DetailsItem>
						)}
						{project.year && (
							<DetailsItem smallFirstCol>
								<p>Completion</p>
								<p className="span-end">{project.year}</p>
							</DetailsItem>
						)}
						{project.type && (
							<DetailsItem smallFirstCol>
								<p>Type</p>
								<p className="span-end">{project.type}</p>
							</DetailsItem>
						)}
						{project.client && (
							<DetailsItem smallFirstCol>
								<p>Client</p>
								<p className="span-end">{project.client}</p>
							</DetailsItem>
						)}
						{!!project.additionalCredits?.length && (
							<>
								{project.additionalCredits.map((c) => (
									<DetailsItem smallFirstCol key={c.leftColumn}>
										<p>{c.leftColumn}</p>
										<p className="span-end">{c.rightColumn}</p>
									</DetailsItem>
								))}
							</>
						)}
					</ProjectMeta>
					{project.description && (
						<ProjectText cols={2}>
							<BlockText value={project.description} />
						</ProjectText>
					)}
				</ProjectInfo>

				{/* <div
					css={css`
						position: fixed;
						font-weight: 800;
						font-size: 60px;
						top: 50%;
						right: 24px;
						transform: translateY(-50%);
						z-index: 5;
						color: red;
					`}
				>
					active: {activeSlide}
				</div> */}

				{content.map((slide, i) => {
					const type = slide.slideType;

					return (
						<ProjectSlide
							slide={slide}
							key={slide._key}
							i={i + 1}
							type={type}
							activeSlide={activeSlide}
							setActiveSlide={setActiveSlide}
							// visibleSlides={visibleSlides}
							// setVisibleSlides={setVisibleSlides}
						/>
					);
				})}
			</ContentWrap>
		</>
	);
};

// const ContentWrap = styled(Swiper)``;
const ContentWrap = styled.div`
	// scroll-snap-type: y mandatory;
	position: relative;
`;

const ProjectInfo = styled.div`
	${mobileUp} {
		padding: var(--padding);
		padding-bottom: 0;
		/* min-height: 100vh; */
	}

	${mobile} {
		padding: var(--padding);
		padding-bottom: 0;
	}
`;

const ProjectMeta = styled.div`
	padding-bottom: var(--padding-sml);
	border-bottom: var(--border-black);
	font-family: var(--hermes);
	font-size: var(--accent-size);
	line-height: var(--accent-line);
	letter-spacing: var(--accent-letter);

	h3 {
		margin-bottom: var(--body-line);
	}
`;

const DetailsItem = styled.div`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	column-gap: var(--gap);

	${tablet} {
		grid-template-columns: ${(props) =>
			props.smallFirstCol ? "1fr 3fr" : "1fr 5fr"};
	}

	${mobile} {
		grid-template-columns: ${(props) =>
			props.smallFirstCol ? "1fr 2fr" : "1fr 5fr"};
	}

	> p:first-child {
		overflow-wrap: anywhere;
		hyphens: auto;
	}

	.span-end {
		grid-column: 2/-1;
	}
`;

const ProjectText = styled(Section)`
	padding-block: 140px;
	padding-inline: 78px;
	p:not(:only-child) {
		margin-bottom: var(--body-line);
		&:last-child {
			margin-bottom: 0;
		}
	}

	${mobile} {
		padding: var(--padding-lrg) 0;
	}
`;

const SlideNav = styled.div`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 50vh;
	transform: translateY(-50%);
	left: var(--padding);
	width: 8px;
	height: max-content;
	z-index: 5;
	transition: opacity var(--transition-400);
	opacity: ${(props) => (props.showNav ? 1 : 0)};
	pointer-events: ${(props) => (props.showNav ? "all" : "none")};
	/* mix-blend-mode: plus-lighter; */
`;

const NavDot = styled.button`
	margin-bottom: 6px;
	border-radius: 50%;
	width: 8px;
	height: 8px;
`;

export default ProjectContent;
